@import-normalize; /* bring in normalize.css styles */

a#hidden_link {
  color: inherit !important;
  text-decoration: none !important;
}

a:hover {
  color: inherit !important;
  text-decoration: underline !important;
}
